<template>
  <v-app v-if="i18nLoaded">
    <Navbar
      ref="header"
      :key="key"
      :links="siteLinks"
      :user="user"
      :applicationConfig="applicationConfig"
    />
    <v-navigation-drawer
      class="drawer-left"
      v-model="drawerLeft"
      :temporary="true"
      touchless
      floating
      app
      top
    >
      <!-- touchless
      left
      app
      clipped -->
      <CategoryTree
        :infos="siteLinks"
        @close="drawerLeft = false"
        v-if="$vuetify.breakpoint.smAndDown"
      />
      <MasonryCategoryMenu @close="drawerLeft = false" v-else />
    </v-navigation-drawer>

    <!-- drawer destro di carrello -->
    <v-navigation-drawer
      v-if="isAuthenticated"
      v-model="drawerRight"
      touchless
      fixed
      temporary
      clipped
      right
      app
      width="680"
      class="cart-drawer"
      id="cart-right-drawer"
    >
      <div class="cart-info">
        <CartInfoList :isCart="true" />
      </div>

      <CartItemList class="cart-item-list-drawer" :full="false" />
      <CartSummary />
    </v-navigation-drawer>

    <!-- <v-navigation-drawer
      class="drawer-links"

      :stateless="!isMobile"
      v-model="drawerLinks"
      touchless
      right
      app
    >
      <MenuLinksList :links="siteLinks" @close="siteLinks = false" />
    </v-navigation-drawer> -->
    <!-- drawer sinistro di navigazione categorie -->

    <v-main>
      <ebsn-style
        v-if="applicationConfig"
        :target="applicationConfig"
        path="category_advanced.CSS_STYLE"
      />
      <div class="content-wrap">
        <v-skeleton-loader
          type="actions, image, article,image"
          v-if="loading"
          :loading="loading"
        ></v-skeleton-loader>
        <router-view v-else></router-view>
      </div>
      <v-footer class="pb-0 mt-8">
        <!-- <v-btn @click="onResume">reload</v-btn> -->
        <Footer :links="footerLinks" />
      </v-footer>
    </v-main>

    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        outlined
        bottom
        right
        small
        color="primary"
        class="toTop"
        @click="toTop"
      >
        <v-icon>$chevronUp</v-icon>
      </v-btn>
    </v-fab-transition>
    <SsoUserCard />
  </v-app>
</template>
<script>
import CartSummary from "@/components/cart/CartSummary";
import CartInfoList from "@/components/cart/CartInfoList";
import CartItemList from "@/components/cart/CartItemList";
import CategoryTree from "@/components/navigation/CategoryTree";
import MasonryCategoryMenu from "@/components/navigation/MasonryCategoryMenu";
import SsoUserCard from "@/components/profile/SsoUserCard.vue";
import Footer from "@/components/navigation/Footer";
import Navbar from "@/components/navigation/Navbar";

import login from "~/mixins/login";
import version from "~/mixins/version";
import { mapActions, mapGetters, mapState } from "vuex";
import analyticsService from "~/service/analyticsService";
import CategoryService from "~/service/categoryService";

import { setLocale } from "@/plugins/i18n";
import findIndex from "lodash/findIndex";

export default {
  name: "App",
  components: {
    CategoryTree,
    CartInfoList,
    CartSummary,
    CartItemList,
    MasonryCategoryMenu,
    SsoUserCard,
    Footer,
    Navbar
  },
  mixins: [login, version],
  data() {
    return {
      fab: false,
      applicationConfig: null,
      i18nLoaded: false,
      load404: false,
      initialHref: window.location.href,
      loading: true,
      key: 1,
      navBarLinks: [],
      footerLinks: [],
      siteLinks: [],
      blocks: {
        onlineStore: null,
        perche: null,
        viaggi: null
      }
    };
  },
  metaInfo: {
    title: "Eurospin",
    titleTemplate: "%s | Eurospin Spesa Online",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content:
          "Eurospin Spesa Online spesa online è il nuovissimo servizio di Eurospin che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      {
        name: "keywords",
        content: "Eurospin Spesa Online supermercato"
      },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "author", content: "Digitelematica s.r.l." }
    ]
  },
  computed: {
    ...mapGetters({
      needRestart: "needRestart",
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    drawerRight: {
      get() {
        return this.$store.state.app.drawerRight;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerRight", value);
      }
    },
    drawerLinks: {
      get() {
        return this.$store.state.custom.drawerLinks;
      },
      set(value) {
        this.$store.commit("custom/updatedDrawerLinks", value);
      }
    },
    drawerSearch: {
      get() {
        return this.$store.state.custom.drawerSearch;
      },
      set(value) {
        this.$store.commit("custom/updatedDrawerSearch", value);
      }
    }
  },
  methods: {
    ...mapActions({
      updateAuth: "cart/updateAuth",
      resetFilters: "category/resetFilters",
      updateLastStartTime: "app/updateLastStartTime"
    }),
    // fetchNavBarLinksMenu() {
    //   CmService.getMenuByLocation("main_menu").then(
    //     data => (this.navBarLinks = data)
    //   );
    // },
    // fetchFooterLinksMenu() {
    //   let footerSlug = "footer";
    //   if (this.cart.store) {
    //     if (this.cart.store.storeId === 61) {
    //       footerSlug = "footer-sicilia";
    //     } else if (this.cart.store.storeId === 62) {
    //       footerSlug = "footer-sardegna";
    //     }
    //   }

    //   CmService.getMenuByLocation(footerSlug).then(
    //     data => (this.footerLinks = data)
    //   );
    // },
    async fetchApplicationConfig() {
      try {
        let result = await CategoryService.getCategoryBySlug("application");
        this.applicationConfig = result;
        this.footerLinks = result.templateNavItems.footer;
        this.siteLinks = result.templateNavItems.header;
      } catch (err) {
        console.log(err);
      }
    },
    // using version 2 of all blocks (for Vue implementation without using old AngularJS styles and html)

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    async onResume() {
      var _this = this;
      var hash;
      setTimeout(async function() {
        hash = findIndex(global.config.hashNotReload, function(o) {
          return window.location.hash.indexOf(o) !== -1;
        });
        var needRestart =
          new Date().getTime() - _this.$store.state.app.lastStartTime >
          global.config.reloadAfterMillis;
        if (hash === -1 && needRestart) {
          global.EventBus.$emit("restart");
        } else {
          if (
            hash === -1 &&
            window.sessionStorage.getItem("is-authenticated")
          ) {
            await _this.loadCart();
          }
          global.EventBus.$emit("reload");
        }
      }, 100);
    },
    async handleLogin() {
      this.doLogin("response419");
    },
    ...mapActions({
      loadCart: "cart/loadCart"
    })
  },
  async created() {
    var _this = this;
    this.fetchApplicationConfig();
    _this.load404 = false;
    try {
      await setLocale("it");
      this.i18nLoaded = true;
      if (
        window.sessionStorage.getItem("is-authenticated") ||
        window.sessionStorage.getItem("X-Ebsn-Session") ||
        window.localStorage.getItem("X-Ebsn-Account") ||
        window.location.href.indexOf("checkUser") !== -1
      ) {
        await _this.loadCart();
      } else {
        analyticsService.initCustomDimension();
      }
    } finally {
      _this.loading = false;
    }

    //_this.fetchNavBarLinksMenu();
    //_this.fetchFooterLinksMenu();

    global.EventBus.$on("isNotLoggedIn", () => {
      _this.updateAuth();
      _this.handleLogin();
    });
    global.EventBus.$on("restart", () => {
      _this.updateLastStartTime();
      window.location.reload();
    });
    global.EventBus.$on("resetFilters", () => {
      _this.resetFilters(Math.random());
    });
    setTimeout(function() {
      _this.key = _this.key + 1;
    }, 3000);
    //eventi legati a cordova, partono solo al deviceready che viene scatenato da cordova quanto la app è pronta
    document.addEventListener(
      "deviceready",
      () => {
        console.log("deviceready ::::: ", window.innerWidth);
        setTimeout(function() {
          if (window.innerWidth < 600) {
            screen.orientation.lock("portrait");
          }
        }, 1500);

        //save last start time
        _this.updateLastStartTime();

        document.addEventListener("resume", _this.onResume, false);

        //con x secondi controllo se c'è una versione più recente
        _this.checkVersion();
      },
      false
    );
  },
  watch: {
    $route(to) {
      this.load404 = false;
      if (to.meta.load404) {
        this.load404 = to.meta.load404;
      }
    },
    "cart.store"(val, oldVal) {
      val?.storeId !== oldVal?.storeId;
    }
  }
};
</script>

let routes = [
  {
    path: "/checkout",
    name: "Checkout",
    meta: {
      requiresAuth: true,
      slug: "checkout"
    },
    component: () => import("@/views/checkout/Checkout.vue")
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      requiresAuth: true,
      slug: "payment"
    },
    component: () => import("@/views/checkout/Payment.vue")
  },

  {
    path:
      "/stripe-pay-payment/:order/:payment_client_secret/:orderGrossTotal/:timeSlotDate/:timeSlotTime/:redirect_url_recurrent/:payment_data/:payment_auth_id",
    name: "StripePayPayment",
    meta: {
      requiresAuth: true,
      slug: "stripe-pay-payment"
    },
    component: () => import("@/views/checkout/StripePayPayment.vue")
  },

  {
    path: "/cart/payment-error/:orderId",
    name: "PaymentError",
    meta: {
      requiresAuth: true,
      slug: "payment-error"
    },
    props: true,
    component: () => import("@/views/checkout/PaymentCompleted.vue")
  },
  {
    path: "/cart/payment-completed/:orderId",
    name: "PaymentCompleted",
    meta: {
      requiresAuth: true,
      slug: "payment-completed"
    },
    props: true,
    component: () => import("@/views/checkout/PaymentCompleted.vue")
  }
];

export default routes;

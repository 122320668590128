let routes = [
  {
    path: "/store-locator",
    name: "WarehouseLocator",
    meta: {
      breadCrumb: "Punti Vendita",
      slug: "store-locator"
    },
    component: () => import("@/views/store/WarehouseLocator.vue")
  },
  {
    path: "/store-locator/:slug",
    name: "WarehouseRegion",
    meta: { breadCrumb: "Punti Vendita" },
    props: route => ({
      slug: route.params.region
    }),
    component: () => import("@/views/store/WarehouseRegion.vue")
  },
  {
    path: "/store-locator/:region/:slug",
    name: "WarehouseProvince",
    meta: { breadCrumb: "Punti Vendita" },
    props: route => ({
      region: route.params.region,
      province: route.params.province
    }),
    component: () => import("@/views/store/WarehouseProvince.vue")
  },
  {
    path: "/store-locator/:region/:province/:slug",
    name: "WarehouseDetail",
    meta: { breadCrumb: "Punti Vendita" },
    props: route => ({
      region: route.params.region,
      province: route.params.province,
      warehouseId: route.params.warehouseId
    }),
    component: () => import("@/views/store/WarehouseDetail.vue")
  }
];

export default routes;

<template>
  <div ref="menu" class="masonry-category-menu px-4">
    <div class="d-flex masonry-row">
      <div class="all-products" flat>
        <v-list flat :height="listHeight">
          <v-list-item-group v-model="selectedCategoryFirstLevel">
            <v-list-item
              v-for="category in categories"
              :key="category.categoryId"
              @click.native.prevent="selectCategory(category)"
              dense
              class="px-1"
            >
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <span class="mr-3 category-icon">
                    <img
                      :src="
                        category.metaData
                          ? category.metaData.category_info.ICON
                          : ''
                      "
                      class=""
                    />
                  </span>
                  <router-link
                    class="menu-link"
                    :to="{
                      name: 'Category',
                      params: { pathMatch: category.slug }
                    }"
                    event
                    @click.native.prevent="selectCategory(category)"
                  >
                    {{ category.name }}
                  </router-link>
                  <v-spacer></v-spacer>
                  <v-icon small color="grey lighten-1">$chevronRight</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="category2 px-4" flat v-if="categorySelected">
        <v-list class="text-body-1">
          <v-list-item
            v-for="category2 in categorySelected.categories"
            :key="category2.categoryId"
            :to="{
              name: 'Category',
              params: { pathMatch: category2.slug }
            }"
            link
            @click.native.prevent="close"
          >
            {{ category2.name }}
          </v-list-item>
        </v-list>
      </div>
      <!-- <div class="category-block-menu-container"> -->
      <category-block
        v-if="categoryOpened"
        :target="categoryOpened"
        position="menu"
        class="category-block category-block-menu mt-3"
        :container="false"
      />
      <!-- </div> -->
    </div>
  </div>
</template>
<style lang="scss">
.masonry-category-menu {
  .masonry-row {
    // .all-products {
    //   flex-basis: 25%;
    // }
    // .category2 {
    //   flex-basis: 25%;
    // }
    // .category-block-menu {
    //   flex-basis: 50%;
    // }
    // .category-block-menu-container {
    //   flex-basis: 50%;
    //   .category-block-menu {
    //   }
    // }
  }
  .category-icon {
    width: 30px;
  }
  .menu-link {
    font-size: 16px;
    font-weight: 400;
  }
  .v-navigation-drawer .v-list:not(.v-select-list) {
    background: transparent;
  }
  .category2 {
    .v-list {
      width: fit-content;
      min-width: 300px;
    }
  }
  .v-menu__content {
    max-width: 90% !important;
    width: 90%;
  }
  .menu {
    background: $white !important;
    height: 100%;
  }

  .v-list-item {
    border-bottom: 1px solid var(--v-background-base);
    transition: 0.5s;
    &:hover {
      background-color: var(--v-background-base) !important;
    }

    .v-list-item__title {
      line-height: 1.3;
    }
  }
  .all-products {
    padding: 0px;
    background-color: $white;
    font-size: 16px;
    color: $text-color;
    width: 500px;
    min-width: 320px;
    .v-list-item--dense .v-list-item__content {
      padding: 4px 0;
    }
    .link-active {
      color: $secondary;
    }
    .subheaders {
      // color: rgba(255, 255, 255, 0.57) !important;
      color: $text-color;
      font-size: 16px;
      padding: 0 16px;
    }
    a {
      color: $text-color;
      text-decoration: none;
    }
    .v-list {
      overflow-y: auto;
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        padding-bottom: 50px;
      }
    }

    .v-list-item--active {
      background-color: var(--v-background-base) !important;
      a {
        font-weight: 600;
      }
      &:hover {
        background-color: var(--v-background-base) !important;
      }
    }
    .v-item--active {
      color: var(--v-primary-lighten1);
      font-weight: 400;
      .v-list-item__title a {
        color: var(--v-primary-lighten1);
      }
    }
  }
  .category2 > div {
    .v-list-item {
      border-bottom: 1px solid var(--v-background-base);
    }
    .v-list-item__title,
    .v-list-item__content,
    .v-item-group {
      border-color: transparent;
      border: 0px none;
    }
  }
  .category2 {
    background-color: $white; /*Default bg, similar to the background's base color*/
    background-position: right bottom; /*Positioning*/
    background-repeat: no-repeat; /*Prevent showing multiple background images*/
    font-size: 12px;
    color: $black;
    width: 100%;
    a {
      color: inherit;
      text-decoration: none;
      font-size: 15px;
    }
    .masonry-list {
      padding: 20px 0;
    }
    .v-list-item__content {
      color: $text-color !important;
      padding: 5px 0;
    }

    .link-active:before {
      background: $secondary;
      opacity: 0.2;
    }
  }
}
</style>
<script>
import categoryService from "@/commons/service/categoryService";

import { mapState, mapActions } from "vuex";

export default {
  name: "MasonryCategoryMenu",
  data() {
    return {
      menu: false,
      selectedCategoryFirstLevel: 0,
      categorySelected: null,
      // DA TESTARE CON I VARI BREAKPOINTS FINO A TABLET "MD".
      masonryCols: { default: 4, 1744: 4, 1264: 3, 960: 2, 400: 1 },
      masonryGutters: {
        default: "3px"
      },
      allCategories: [],
      listHeight: "100%",
      categoryOpened: null,
      firstLevelCategories: []
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    getCategoriesBySlug() {
      var _this = this;
      var categoryObject = {};
      _this.allCategories = [];
      if (this.categorySelected) {
        this.categories.forEach(element => {
          if (element.categoryId === _this.categorySelected.categoryId) {
            element.categories.forEach(category => {
              categoryObject = category;
              _this.allCategories.push(categoryObject);
            });
          }
        });
      }
      return _this.allCategories;
    }
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    onResize() {
      this.listHeight = document.getElementsByClassName(
        "drawer-left"
      )[0].offsetHeight;
    },
    selectCategory(cat) {
      if (cat) {
        this.categorySelected = cat;
      } else {
        this.categorySelected = { categories: this.categories };
      }
      this.fetchCategory(cat);
    },
    async fetchCategory(cat) {
      let vm = this;
      if (vm.firstLevelCategories[cat.categoryId] != undefined) {
        vm.categoryOpened = vm.firstLevelCategories[cat.categoryId];
      } else {
        let result = await categoryService.getCategoryBySlug(cat.slug);
        vm.firstLevelCategories[cat.categoryId] = result;
        vm.categoryOpened = result;
      }
    },
    close() {
      this.menu = false;
    }
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    this.reload();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    categories() {
      if (this.categories && this.categories.length) {
        this.selectCategory(this.categories[0]);
      }
    }
  }
};
</script>

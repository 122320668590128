<template>
  <v-card
    class="py-2 px-3 text-right cart-summary d-flex flex-column justify-end"
    tile
    flat
    v-if="cart.cartId > 0"
  >
    <v-card-text class="px-3">
      <!-- <div>
        <span class="info-text">
          Totale Ordine
        </span>
        <span class="info-value">
          {{ $n(cart.totalPrice, "currency") }}
        </span>
      </div>
      <div>
        <span class="info-text"> Totale Iva </span>
        <span class="info-value">
          {{ $n(cart.taxTotal, "currency") }}
        </span>
      </div>
      <div v-if="full">
        <div
          v-for="gift in cart.giftCertificates"
          :key="gift.giftCertificateId"
          class="d-flex justify-space-between align-center"
        >
          <strong>{{ gift.description }} pane</strong>
          <v-btn icon @click="removeCode(gift.giftCertificateId)">
            <v-icon color="grey lighten-1">$delete</v-icon>
          </v-btn>
        </div>
      </div> -->
      <div v-if="full">
        <div
          v-for="orderPackage in cart.orderPackages"
          :key="orderPackage.orderPackageId"
        >
          <strong>
            <span class="info-text">
              {{ orderPackage.package.descr }}
            </span>
            <span class="info-value">
              ({{ orderPackage.quantity }}x{{
                $n(orderPackage.package.cost, "currency")
              }})
              {{ $n(orderPackage.totalPrice, "currency") }}
            </span>
          </strong>
        </div>

        <div
          v-for="gift in cart.giftCertificatesToGenerate"
          :key="gift.gigftCertificateId"
        >
          <strong>
            <span class="info-text"> {{ gift.name }} </span>
            <span class="info-value" v-if="gift.giftTotal">
              {{ $n(gift.giftTotal, "currency") }}
            </span>
          </strong>
        </div>

        <!-- <div class="text-subtitle-1" v-if="cart.deliveryFee > 0">
          <strong>
            <span class="info-text">
              Spese di consegna
            </span>
            <span class="info-value">
              {{ $n(cart.deliveryFee, "currency") }}
            </span>
          </strong>
        </div> -->
      </div>
      <!-- <div class="text-h4"> -->
      <strong class="total-value">
        <span>TOTALE </span>
        <span class="total-info-value">
          {{ $n(cart.grossTotal, "currency") }}
        </span>
      </strong>
      <!-- </div> -->
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-row no-gutters class="flex-wrap">
        <v-col
          sm="12"
          md="6"
          offset-md="6"
          class="pa-1"
          v-if="cart.totalItems > 0"
        >
          <!-- v-on:click="emptyCart" -->
          <v-btn
            class="continue-shopping-btn"
            depressed
            block
            x-large
            min-width="100%"
            v-on:click="closeDrawer"
            v-if="!full"
            ><v-icon large class="white--text">mdi-chevron-left</v-icon>Torna
            alla spesa</v-btn
          >
        </v-col>
        <!-- <v-col sm="12" md="4" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn class="show-cart-btn" to="/checkout" min-width="100%" depressed
            >Visualizza carrello</v-btn
          >
        </v-col> -->
        <v-col sm="12" md="6" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn
            block
            x-large
            color="primary lighten-1"
            depressed
            min-width="95%"
            v-on:click="fastPayment"
            v-if="!full"
          >
            <span>{{ $t("cart.button.fastPayment") }}</span>
          </v-btn>
        </v-col>

        <v-col sm="12" md="6" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn
            block
            class="secondary"
            x-large
            to="/checkout"
            min-width="100%"
            depressed
            >Concludi ordine</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style scoped lang="scss">
// .cart-summary {
//   background-color: var(--v-grey-lighten2) !important;
// }
.v-navigation-drawer {
  .cart-summary {
    position: fixed;
    bottom: 0;
    width: 100%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      position: absolute;
    }
  }
}
</style>
<script>
import { mapState, mapActions } from "vuex";
import FastPayment from "@/components/cart/FastPayment.vue";

export default {
  name: "CartSummary",
  props: ["full"],
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart"
    }),
    ...mapActions({
      removeGiftCode: "cart/removeGiftCode",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    async fastPayment() {
      let _this = this;
      let res = await _this.$dialog.show(FastPayment, {
        waitForResult: true,
        width: 500
      });
      if (res) {
        // let items = [];
        // forEachCartItem(_this.cart, function(item) {
        //   items.push(item);
        // });
        // await ListService.addProductsToList(res.listId, items);
      }
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>

<template>
  <div class="d-flex align-center px-2">
    <v-btn
      color="white"
      elevation="0"
      :loading="loading"
      small
      icon
      @click="scan()"
    >
      <v-icon medium>
        $barcodeScan
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "Barcode",
  props: ["formats"],
  data() {
    return {
      loading: false
    };
  },
  computed: {},
  methods: {
    scan() {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loading = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            result => {
              if (result) {
                console.log("BarCode Read: ", result.text);
                this.loading = false;
                this.$emit("input", {
                  type: "Barcode",
                  name: result.text
                });
              }
            },
            error => {
              this.loading = false;
              console.error("Scanning failed", error);
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              formats:
                this.formats ||
                "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR,UPC_A,UPC_E"
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
};
</script>

<template>
  <v-card
    flat
    class="card-info-wrapper"
    :class="[
      { small: small },
      {
        fixedHeight:
          isShopopop || (!isShopopop && $vuetify.breakpoint.smAndDown)
      }
    ]"
    @click="edit"
  >
    <div class="d-flex flex-row">
      <img aria-hidden :src="icon" />
      <div class="card-info pa-2">
        <span class="title-info">{{ text }}</span>
        <span :class="{ ellipsis: !isCheckout }">{{ infoValue }}</span>
      </div>
    </div>
    <v-icon small color="secondary" right v-if="editable">
      $edit
    </v-icon>
  </v-card>
</template>
<style scoped lang="scss">
.fixedHeight {
  height: 91px;
}
.card-info-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 4px !important;
  align-items: start;

  img {
    width: 45px;
  }
  .card-info {
    display: flex;
    flex-direction: column;

    span {
      font-size: 16px;
    }
    .title-info {
      text-transform: uppercase;
      font-weight: 600;
      color: $text-color !important;
    }
    .ellipsis {
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.small {
    padding: 10px;
    img {
      width: 35px;
    }
    .card-info {
      span {
        font-size: 12px;
      }
    }
  }
}
</style>
<script>
export default {
  name: "CartInfoCard",
  props: {
    icon: { type: String, required: true },
    text: { type: String, required: true },
    infoValue: { type: String, required: true },
    editable: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: false },
    isShopopop: { type: Boolean, required: false }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"card-info-wrapper",class:[
      { small: _vm.small },
      {
        fixedHeight:
          _vm.isShopopop || (!_vm.isShopopop && _vm.$vuetify.breakpoint.smAndDown)
      }
    ],attrs:{"flat":""},on:{"click":_vm.edit}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{attrs:{"aria-hidden":"","src":_vm.icon}}),_c('div',{staticClass:"card-info pa-2"},[_c('span',{staticClass:"title-info"},[_vm._v(_vm._s(_vm.text))]),_c('span',{class:{ ellipsis: !_vm.isCheckout }},[_vm._v(_vm._s(_vm.infoValue))])])]),(_vm.editable)?_c('v-icon',{attrs:{"small":"","color":"secondary","right":""}},[_vm._v(" $edit ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="autocomplete d-flex flex-row">
    <v-combobox
      v-model="selected"
      ref="autocomplete"
      :items="getItems"
      :search-input.sync="search"
      :label="$t(placeholder)"
      :placeholder="$t(placeholder)"
      :menu-props="{ closeOnContentClick: true }"
      no-filter
      item-text="name"
      item-value="name"
      clearable
      solo
      autofocus
      hide-details
      hide-no-data
      return-object
      light
      :dense="$vuetify.breakpoint.xsOnly"
      type="search"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter.native.prevent.stop="doSearch"
    >
      <template v-slot:append>
        <v-btn
          @click="doSearch"
          class="searchBtnIcon"
          height="48"
          width="48"
          min-width="48"
          color="secondary"
          depressed
        >
          <v-icon>$search</v-icon>
        </v-btn>
      </template>

      <template v-slot:item="{ item }" class="search-item">
        <div
          class="d-flex flex-row w-100"
          :class="{ fastSearch: item.type == 'FastSearch' }"
        >
          <v-list-item-icon class="search-icon">
            <img
              v-if="item.type == 'Categorie'"
              :src="getImage(item)"
              onerror="this.src='/no-image-grey.png'"
            />
            <v-icon
              v-else-if="item.type == 'Ricerche'"
              @click.prevent.stop="deleteWord(item)"
              small
            >
              $close
            </v-icon>
            <v-icon
              v-else-if="item.type == 'FastSearch'"
              small
              color="primary lighten-1"
              >$list</v-icon
            >
            <v-icon v-else small>$search</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            @click.prevent.stop="
              item.type == 'FastSearch'
                ? openFastSearchDialog()
                : doSearch(item)
            "
          >
            <v-list-item-title>
              <text-highlight
                :queries="search ? search : '*'"
                v-if="item.type != 'FastSearch'"
              >
                {{ item.name }}
              </text-highlight>
              <div v-else>{{ item.name }}</div>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.type == 'Categorie'">
              Cerca <strong>{{ search }}</strong> in {{ item.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.type == 'Marche'">
              Cerca <strong>{{ search }}</strong> con marca {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div class="badge" v-if="item.count">
              {{ item.count }}
            </div>
            <v-icon
              v-if="item.type == 'Ricerche'"
              @click.prevent.stop="search = item.name"
              small
            >
              $arrowTopLeft
            </v-icon>
          </v-list-item-action>
        </div>
      </template>
    </v-combobox>
    <Barcode
      class="profile-nav"
      v-if="$platform_is_cordova"
      @input="doSearch"
    />
  </div>
</template>
<style scoped lang="scss">
main,
.autocomplete {
  width: 100% !important;
  .v-icon--link {
    color: var(--v-secondary-base) !important;
    margin-right: 5px;
  }
  :deep(.v-input__slot) {
    padding-right: 0 !important;
  }
}
.searchBtnIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 $border-radius-root $border-radius-root 0;
}
.search-item {
  min-height: 36px;
}
.fastSearch {
  color: var(--v-primary-lighten1) !important;
  font-weight: 700;
}
:deep(.v-list) {
  padding: 0 !important;
  .v-list-item--link {
    width: 100%;
    &:hover {
      background-color: var(--v-primary-base) !important;
      .v-list-item__title {
        color: $white;
      }
      .v-list-item__icon.search-icon .v-icon,
      .v-list-item__action .v-icon {
        color: var(--v-primary-lighten1) !important;
      }
    }
  }
}

.search-icon {
  margin-right: 5px !important;
  img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
}
.icon-fast-search {
  color: var(--v-primary-lighten1);
}
</style>
<script>
import Barcode from "./Barcode";
import FastSearchMenu from "./FastSearchMenu";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";
import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

var vm;
export default {
  components: {
    Barcode
  },
  props: {
    focusOnOpen: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "navbar.searchPlaceholder"
    },
    appendIcon: {
      type: String,
      required: false,
      default: "$search"
    }
  },
  data() {
    return {
      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      items: [],
      dialogInstance: null,
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      let result = this.search ? this.items : this.commonSearch;
      result.unshift({
        type: "FastSearch",
        name: "Lista della spesa"
      });
      return result;
    }
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    getImage(item) {
      return "/search-category/" + item.id + ".png";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      this.loading = true;
      if (this.search) {
        await ProductService.suggest(this.search.trim()).then(function(
          response
        ) {
          let newItems = [];
          let prevType = "none";

          forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
          vm.items = newItems;
          vm.loading = false;
        });
      }
    }, 200),
    doSearch(item) {
      this.items = [];
      this.$emit("closeSearchModal", true);
      var currentQuery = {};
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "FastSearch") {
        currentQuery.q = "";
        console.log("currentQuery.q", currentQuery.q);
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }

      if (currentQuery.q || currentQuery.barcode) {
        AnalyticsService.search(this.search);
        this.$store.dispatch("category/resetFilters");
        this.$router.push({
          // path: "/search?q=:q&parent_category_id=:parent_category_id",
          path: "/search",
          name: "Search",
          query: currentQuery
        });

        this.$nextTick(() => {
          const autocomplete = this.$refs.autocomplete;
          autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },
    async openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: this.$vuetify.breakpoint.xs
      };
      let properties = {
        waitForResult: true,
        width: 500
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = await this.$dialog.show(FastSearchMenu, config);
      const result = await this.dialogInstance.wait();
      return result;
    }
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
    global.EventBus.$off("focusSearch");
  },
  mounted() {
    vm = this;
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
    global.EventBus.$on("focusSearch", () => {
      setTimeout(() => {
        this.$refs.autocomplete.focus();
      }, 200);
    });
    if (this.focusOnOpen) {
      this.$refs.autocomplete.focus();
    }
  }
};
</script>

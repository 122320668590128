<template>
  <v-list flat subheader dense class="py-0">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title v-if="!isCart">
          <span class="service-name">{{ serviceName }}</span>
        </v-list-item-title>
        <v-list-item-subtitle class="d-flex flex-column align-left">
          <v-row no-gutters class="d-flex align-center">
            <v-col cols="6" class="pr-1">
              <CartInfoAddressCard
                :shippingAddress="cart.shippingAddress"
                :editable="editableAddress"
                @edit="openAddressSelector"
                :isCheckout="false"
              />
            </v-col>
            <v-col cols="6" class="pl-1">
              <CartInfoTimeslotCard
                :shippingAddress="cart.shippingAddress"
                :timeslot="cart.timeslot"
                :editable="editableTimeslot"
                @edit="openTimeslotSelector"
                :isCheckout="false"
              />
            </v-col>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<style lang="scss" scoped>
.row {
  min-height: 30px;
}

.cart-info-button {
  background-color: transparent !important;
  text-transform: none !important;
  justify-content: left;
  padding: 0 8px !important;
  font-size: 13px;
  // height: 16px !important;
  height: 100% !important;
  letter-spacing: normal;
  .v-btn__content {
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-icon {
    font-size: 12px;
    height: 12px;
    width: 12px;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 0.5em;
    color: white;
    border: 1px solid white;
    background-color: var(--v-primary-base);
    padding: 3px;
  }
}
.row-label {
  font-weight: 700;
}

.cart-info-button-label {
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit {
  text-decoration: underline;
  font-weight: 700;
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 1px 0;
}
</style>
<script>
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  components: {
    CartInfoAddressCard,
    CartInfoTimeslotCard
  },
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: { isCart: { type: Boolean, required: false } }
};
</script>

<template>
  <CartInfoCard
    :infoValue="value"
    :text="text"
    :icon="`/services-icons/${serviceImage}.svg`"
    :editable="editable"
    :isCheckout="isCheckout"
    @edit="edit"
    :small="small"
  />
</template>
<script>
import CartInfoCard from "./CartInfoCard";
import { mapState } from "vuex";
export default {
  name: "CartInfoAddressCard",
  props: {
    shippingAddress: { type: Object, required: true },
    editable: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: true }
  },
  data() {
    return {
      serviceImage: null
    };
  },
  components: { CartInfoCard },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    image() {
      return this.$t(
        "navbar.service." + this.shippingAddress.deliveryServiceId + ".icon",
        {
          color: "color"
        }
      );
    },
    value() {
      return this.$t(
        "navbar.address." + this.shippingAddress.addressTypeId + ".format",
        this.shippingAddress
      );
    },
    text() {
      if (this.cart.shippingAddress.zone.zoneGroupId == 5) {
        return (
          this.$t(
            "navbar.address." +
              this.shippingAddress.addressTypeId +
              ".addressButtonTitle"
          ) +
          " - " +
          this.$t("cart.checkoutSummary.handledByShopopop")
        );
      } else {
        return this.$t(
          "navbar.address." +
            this.shippingAddress.addressTypeId +
            ".addressButtonTitle"
        );
      }
    }
  },
  methods: {
    setServiceImage(serviceId) {
      if (!serviceId) serviceId = this.cart.shippingAddress.deliveryServiceId;
      if (serviceId === 1) this.serviceImage = "clicca-e-ritira";
      if (serviceId === 2) this.serviceImage = "consegna-a-casa";
      if (serviceId === 4) this.serviceImage = "locker";
    },
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  },
  created() {
    console.log(this.shippingAddress.deliveryServiceId);
    this.setServiceImage();
  },
  watch: {
    "cart.shippingAddress.deliveryServiceId": function(val) {
      this.setServiceImage(val);
    }
  }
};
</script>

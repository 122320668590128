var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 category-tree-container"},[_c('div',{staticClass:"header d-flex justify-space-between align-center"},[_c('v-tabs',{attrs:{"hide-slider":"","active-class":"activeTab","color":"primary"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{key:"categories"},[_c('h3',[_vm._v(_vm._s(_vm.$t("categories.title")))])]),_c('v-tab',{key:"info"},[_c('h3',[_vm._v(_vm._s(_vm.$t("info.title")))])])],1),_c('v-btn',{staticClass:"close-btn",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("icon-close")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{key:"categories"},[_c('v-list',{staticClass:"category-tree",attrs:{"dense":""}},_vm._l((_vm.categories),function(category){return _c('v-list-group',{key:category.categoryId,staticClass:"category1 px-0",scopedSlots:_vm._u([{key:"activator",fn:function(){return [(category.metaData && category.metaData.category_info.ICON)?_c('v-list-item-icon',[_c('v-img',{attrs:{"src":category.metaData.category_info.ICON}})],1):_vm._e(),_c('v-list-item-title',[_c('router-link',{attrs:{"to":{
                    name: 'Category',
                    params: { pathMatch: category.slug }
                  },"event":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.categoryFirstLevelClicked(category)}}},[_vm._v(" "+_vm._s(category.name))])],1)]},proxy:true},{key:"appendIcon",fn:function(){return [_c('v-icon',{attrs:{"small":""}},[_vm._v("$chevronDown")])]},proxy:true}],null,true)},[_vm._l((category.categories),function(category2){return [_c('v-list-item',{key:category2.categoryId,staticClass:"category2",attrs:{"link":"","to":{
                  name: 'Category',
                  params: { pathMatch: category2.slug }
                },"color":"grey lighten-3"}},[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(category2.name))])],1)]}),(_vm.categoryOpened)?_c('category-block',{staticClass:"category-block category-block-menu pa-3",attrs:{"target":_vm.categoryOpened,"position":"menu","container":false}}):_vm._e()],2)}),1)],1),_c('v-tab-item',{key:"info"},[_c('v-list',{staticClass:"info-tab",attrs:{"dense":""}},_vm._l((_vm.infos),function(info){return _c('div',{key:info.ID},[(info.templateNavItems.length <= 0)?_c('v-list-item-title',{staticClass:"category1",class:info.class},[_c('a',{staticClass:"px-4",attrs:{"href":info.itemUrl,"event":""}},[_vm._v(" "+_vm._s(info.name))])]):_c('v-list-group',{staticClass:"category1",attrs:{"prepend-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('a',{staticClass:"px-4",attrs:{"href":info.itemUrl,"event":""}},[_vm._v(" "+_vm._s(info.name))])])]},proxy:true},{key:"appendIcon",fn:function(){return [_c('v-icon',{attrs:{"small":""}},[_vm._v("$chevronDown")])]},proxy:true}],null,true)},_vm._l((info.templateNavItems),function(child,idx){return _c('v-list-item',{key:idx,attrs:{"link":"","href":child.itemUrl}},[_c('v-list-item-title',{},[_vm._v(_vm._s(child.name))])],1)}),1)],1)}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
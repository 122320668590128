<template>
  <div>
    <h3>{{ $t("payment.insertPhoneTitle") }}</h3>
    <p>{{ $t("payment.insertPhoneDesc") }}</p>
    <v-form ref="form" v-model="valid">
      <v-row no-gutters>
        <!-- PREFIX-->
        <v-col cols="2" :sm="full ? 2 : 1">
          <v-text-field
            v-model="billingData.prefix"
            class="text-center"
            :placeholder="billingData.prefix"
            disabled
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="10" :sm="full ? 10 : 4" class="pr-md-2 d-flex flex-row">
          <v-text-field
            dense
            v-model="billingData.phone"
            :label="$t('payment.phone')"
            outlined
            required
            :rules="isMobilePhoneRules"
            @blur="changed"
          />
          <v-btn
            color="primary lighten-1"
            class="ml-1"
            elevation="0"
            height="40"
            large
            :disabled="!valid"
            @click="updateBillingDetails"
          >
            {{ $t("payment.phoneSave") }}
          </v-btn>
        </v-col>
      </v-row>

      <router-link
        :to="{ name: 'Page', params: { pageName: 'privacy-policy' } }"
        class="privacy"
        target="_blank"
      >
        {{ $t("payment.privacyLink") }}
      </router-link>
      <div class="d-flex align-center">
        <v-checkbox
          class="mt-2 conditions text-uppercase"
          hide-details
          required
          v-model="sellCondition"
          false-value="false"
          true-value="true"
        >
        </v-checkbox>
        <i18n path="payment.salesCondition" tag="span" class="mt-3">
          <template v-slot:link>
            <router-link
              :to="{
                name: 'Page',
                params: { pageName: 'condizioni-di-vendita' }
              }"
              target="_blank"
            >
              <span class="primary--text text--lighten-1">
                {{ $t("payment.salesConditionLink") }}</span
              >
            </router-link>
          </template>
        </i18n>
      </div>
    </v-form>
  </div>
</template>

<script>
import RegistrationService from "~/service/userService";

import { mapActions } from "vuex";
import { mapCartInfo } from "~/service/ebsn";

import {
  requiredValue,
  isMobilePhoneStrict
} from "@/validator/validationRules";

export default {
  name: "PaymentInvoice",
  props: {
    full: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      valid: false,
      dirty: false,
      isMobilePhoneRules: [isMobilePhoneStrict(), requiredValue()],
      phoneConfirmed: false,
      billingData: {
        phone: null,
        prefix: "+39"
      }
    };
  },
  computed: {
    ...mapCartInfo({ sellCondition: "accept_sell_condition" })
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),

    changed() {
      if (this.phoneConfirmed) {
        this.validate;
      }
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid =
            _this.$refs.form.validate() &&
            _this.phoneConfirmed &&
            _this.sellCondition === "true";
          _this.$emit("billingFormCompleted", _this.valid);
        }
      }, 200);
    },
    async updateBillingDetails() {
      if (this.$refs.form.validate()) {
        await RegistrationService.updateUserDetail(this.billingData);
        this.phoneConfirmed = true;
        this.validate();
      }
    },
    async getUserDetail() {
      let res = await RegistrationService.getUserDetail();
      if (res.phone && res.phone.length > 0) {
        this.phoneConfirmed = true;
      } else {
        this.phoneConfirmed = false;
      }
      this.billingData = res;
      this.billingData.prefix = "+39";
    }
  },
  async mounted() {
    await this.getUserDetail();
    this.validate();
    if (typeof this.sellCondition === "undefined") {
      this.sellCondition = "false";
    }
  },
  watch: {
    sellCondition() {
      this.validate();
    }
  }
};
</script>
